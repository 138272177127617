<template>
  <!-- 主要內容 -->
  <section class="container pb-8">
    <div class="row">
      <div class="col-12 col-md-5 mx-auto">
        <h2 class="font-weight-bold mb-3 mb-md-6">忘記帳號</h2>
        <!-- 步驟一 -->
        <el-form
          style="width: 100%;"
          :model="MemberForgetAccountForm"
          label-position="top"
          :rules="MemberForgetAccountRules"
          ref="MemberForgetAccountForm"
          class="cs-form"
          v-show="IsNowType === 1"
          @submit.prevent.native
        >
          <div class="form-group mb-3">
            <h5>
              請輸入申請時所填寫的信箱及電話，我們將協助您【找回帳號】，帳號會寄送至申請時所填寫的信箱。
            </h5>
          </div>
          <div class="form-group mb-3">
            <el-form-item prop="memberMail">
              <label class="text-muted" for="memberMail">信箱<span class="pl-1">Email</span></label>
              <input
                type="text"
                class="form-control form-control-lg border-accent"
                id="memberMail"
                placeholder="信箱"
                v-model="MemberForgetAccountForm.memberMail"
              />
            </el-form-item>
          </div>
          <div class="form-group mb-3">
            <el-form-item prop="memberPhone">
              <label class="text-muted" for="memberPhone"
                >電話<span class="pl-1">Phone</span></label
              >
              <input
                type="text"
                class="form-control form-control-lg border-accent"
                id="memberPhone"
                placeholder="電話"
                v-model="MemberForgetAccountForm.memberPhone"
              />
            </el-form-item>
          </div>
          <div class="form-group row">
            <label class="sr-only" for="memberVerify">驗證碼</label>
            <div class="col-8 col-md-6 col-lg-8">
              <input
                type="text"
                class="form-control form-control-lg border-accent"
                id="memberVerify"
                placeholder="驗證碼"
                v-model="MemberForgetAccountForm.captchaCode"
              />
            </div>
            <div class="col-4 d-flex align-items-center">
              <img
                class="img-verify"
                :src="`data:image/png;base64,${CodeImg}`"
                alt="驗證碼"
                @click="GetCaptchaCode"
              />
            </div>
          </div>
          <!-- 送出按紐 -->
          <div class="form-group row mb-6 mb-md-8">
            <div class="col-12">
              <button
                type="button"
                class="btn btn-hover-primary text-white btn-lg border-radius-l w-100"
                @click="MemberForgetAccount"
              >
                <span class="small">確定送出</span>
              </button>
            </div>
          </div>

          <div class="text-center">
            <router-link class="link-hover-accent pr-4" to="/login">
              返回會員登入
            </router-link>
          </div>
        </el-form>
      </div>
    </div>
  </section>
</template>

<script>
import { MemberForgetAccount } from '@/api/MemberApi';
import { GetCaptchaCode, GetCaptchaBase64 } from '@/api/CaptchaApi';

export default {
  data() {
    // 建立你的初始化 model data
    return {
      IsNowType: 1,
      timeOutProcessId: null,
      time: 60,
      isDisabled: false,
      CaptchaCodeTxt: '發送驗證碼',
      CodeImg: '',
      MemberForgetAccountForm: {
        // model data
        memberMail: '',
        memberPhone: '',
        code: '',
        captchaCode: '',
      },
      MemberForgetAccountRules: {
        // 檢查輸入的值規則
        memberMail: [{ required: true, message: '請輸入信箱', trigger: 'blur' }],
        // 檢查輸入的值規則
        memberPhone: [{ required: true, message: '請輸入電話', trigger: 'blur' }],
      },
    };
  },
  created() {
    // 一開始執行的地方
    this.GetCaptchaCode();
  },
  methods: {
    // 你的 js function
    GetCaptchaCode() {
      // 取得驗證碼
      GetCaptchaCode().then((response) => {
        if (response.data.statusCode === 0) {
          this.MemberForgetAccountForm.code = response.data.data.code;
          GetCaptchaBase64(this.MemberForgetAccountForm.code).then((res) => {
            this.CodeImg = res.data;
          });
        }
      });
    },
    MemberForgetAccount() {
      MemberForgetAccount(this.MemberForgetAccountForm).then((response) => {
        if (response.data.statusCode === 0) {
          this.IsNowType = 1;
          this.MemberForgetAccountForm.captchaCode = ''; // 清空資料
          this.MemberForgetAccountForm.memberMail = ''; // 清空資料
          this.MemberForgetAccountForm.memberPhone = ''; // 清空資料
          this.$message({
            showClose: true,
            type: 'success',
            message: '帳號已發送至信箱',
          });
          this.$router.push('/Login');
        } else {
          this.GetCaptchaCode();
          this.IsNowType = 1;
          this.MemberForgetAccountForm.captchaCode = ''; // 清空資料
        }
      });
    },
  },
};
</script>

<style></style>
